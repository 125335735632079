import '../steps.css';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  container: {
    '& div.MuiInputBase-root makeStyles-input-64': {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    background: '#fff',
    padding: '2px 8px',
    borderRadius: '38px',
    display: 'flex',
    alignItems: 'center',
    width: 750,
    height: 66,
    boxShadow: '0px 5px 10px #00000029',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 60,
      flexDirection: 'column',
      borderRadius: '45px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '& button': {
      [theme.breakpoints.down('xs')]: {
        marginTop: '40px',
        width: '104%',
        borderRadius: '45px'
      }
    }
  },
  input: {
    marginLeft: '16px',
    '& input': {
      padding: '0',
      fontSize: '24px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
        marginTop: '16px',
        height: '28px',
        textAlign: 'center'
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
      fontSize: '20px'
    },
    flex: 1
  }
}));

const StyledButton = withStyles((theme) => ({
  root: {
    background: 'transparent',
    backgroundColor: '#F5BC23',
    border: 0,
    height: 56,
    width: 240,
    boxShadow: '0px 2px 6px #00000029',
    borderRadius: '38px',
    color: '#fff',
    '& span': {
      fontWeight: 'bold',
      fontSize: '28px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px'
      }
    },
    '&:hover': {
      backgroundColor: '#F5BC23'
    }
  },
  label: {
    textTransform: 'none'
  }
}))(Button);

export const Step5 = ({ increaseStepper, setEmail, width }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const checkEmail = (str) => {
    let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return re.test(str);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  let history = useHistory();

  return (
    <div className="step step-5 mainContainer flex-center">
      {width < 800 ? <h1>Moving Cost Enquiry</h1> : <div></div>}
      <p>What is your e-mail?</p>
      <span className="sub-text">We respect your privacy and we will only send you e-mail if we find a better quote for you.</span>
      <form
        className={classes.container}
        onSubmit={(e) => {
          e.preventDefault();
          if (checkEmail(e.target[0].value)) {
            increaseStepper();
            history.push('/step7');
          }
          setOpen(true);
        }}
      >
        <InputBase className={classes.input} placeholder="example@mail.com" onChange={(e) => setEmail(e.target.value)} required />
        <StyledButton type="submit" aria-label="search">
          Next
        </StyledButton>
        <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
          <Alert severity="error">Email is not valid!</Alert>
        </Snackbar>
      </form>
    </div>
  );
};
