import '../index.css';
import { Link } from 'react-router-dom';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import Container from '@material-ui/core/Container';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: 'white',
    padding: '3px',
    borderRadius: '22px',
    width: '100%',
    '& .MuiMobileStepper-progress': {
      height: '12px',
      borderRadius: '22px',
      backgroundColor: 'white',
      width: '100%'
    },
    '& .MuiLinearProgress-bar': {
      borderRadius: '22px'
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#F5BC23'
    }
  },
  h1: {
    fontSize: 72
  },
  p: {
    fontSize: 28
  },
  button: {
    textDecoration: 'none',
    width: 300,
    [theme.breakpoints.down('sm')]: {
      width: 'unset'
    }
  },
  img: {
    [theme.breakpoints.down('sm')]: {
      width: 300
    }
  }
}));

const StyledButton = withStyles({
  root: {
    marginBottom: '64px',
    background: 'transparent',
    backgroundColor: '#F5BC23',
    border: 0,
    height: 48,
    width: 300,
    boxShadow: '0px 2px 6px #00000029',
    borderRadius: '38px',
    color: '#fff',
    '& span': {
      fontWeight: 'bold',
      fontSize: '20px'
    },
    '&:hover': {
      backgroundColor: '#F5BC23'
    }
  },
  label: {
    textTransform: 'none'
  }
})(Button);

export const NotFound = () => {
  const classes = useStyles();

  return (
    <div className="container">
      <Container>
        <Header step={1}></Header>
        <Container>
          <h1 className={classes.h1}>Error 404</h1>
          <img alt="" className={classes.img} src="../images/404.png" />
          <p className={classes.p}>Oops! Something seems to be missing</p>
          <Link to="/" className={classes.button}>
            <StyledButton type="submit" aria-label="search">
              Go back to start
            </StyledButton>
          </Link>
        </Container>
        <MobileStepper variant="progress" steps={8} position="static" activeStep={0} className={classes.root} />
        <Footer></Footer>
      </Container>
    </div>
  );
};
