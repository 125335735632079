import React, { useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Landing } from './pages/Landing';
import { NotFound } from './pages/NotFound';
import { Contact } from './pages/Contact';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfUse } from './pages/TermsOfUse';
import { useHistory } from 'react-router-dom';
import BingTracking from 'react-tracking-bing';

const Routes = () => {
  BingTracking.initialize('56343284');

  let history = useHistory();

  history.listen((location, action) => {
    window.gtag('event', 'page_view', {
      page_path: location.pathname
    });
    BingTracking.trackPageview(location.pathname);
  });
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          return <Redirect to="/step1" />;
        }}
      />
      <Route path="/step1">
        <Landing step={0} />
      </Route>
      <Route path="/step2">
        <Landing step={1} />
      </Route>
      <Route path="/step3">
        <Landing step={2} />
      </Route>
      <Route path="/step4">
        <Landing step={3} />
      </Route>
      <Route path="/step5">
        <Landing step={4} />
      </Route>
      <Route path="/step6">
        <Landing step={5} />
      </Route>
      <Route path="/step7">
        <Landing step={6} />
      </Route>
      <Route path="/thank-you">
        <Landing step={7} />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route path="/terms-of-use">
        <TermsOfUse />
      </Route>
      <Route path="*" component={NotFound} />
    </Switch>
  );
};
export const App = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};
