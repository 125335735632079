import '../steps.css';
import Moment from 'moment';
import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import { DateRangePicker } from 'materialui-daterange-picker';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useHistory } from 'react-router-dom';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  alert: {
    bottom: '140px'
  },
  picker: {
    '& .rdrDateDisplayWrapper': {
      borderRadius: '22px 22px 0 0'
    },
    borderRadius: '22px',
    '& .rdrDateDisplayItem': {
      borderRadius: '16px'
    },
    '& span.rdrStartEdge, span.rdrInRange, span.rdrEndEdge': {
      background: '#F5BC23'
    },
    boxShadow: '1px 5px 10px #00000029',
    '&.rdrCalendarWrapper': {
      [theme.breakpoints.down('md')]: {
        fontSize: 10
      }
    },
    '& .rdrDateDisplayItemActive': {
      borderColor: '#F5BC23'
    },
    '& .rdrDayToday .rdrDayNumber span:after': {
      background: '#F5BC23'
    }
  }
}));

const StyledButton = withStyles((theme) => ({
  root: {
    background: 'transparent',
    backgroundColor: '#F5BC23',
    border: 0,
    height: 56,
    width: 240,
    boxShadow: '0px 2px 6px #00000029',
    borderRadius: '38px',
    color: '#fff',
    '& span': {
      fontWeight: 'bold',
      fontSize: '28px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px'
      }
    },
    '&:hover': {
      backgroundColor: '#F5BC23'
    }
  },
  label: {
    textTransform: 'none'
  }
}))(Button);

export const Step2 = ({ increaseStepper, setDateRange, date_from, date_to, width }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [selected, setSelected] = useState(false);
  let history = useHistory();

  const classes = useStyles();

  const checkDate = () => {
    if (date_from === '' && date_to === '') {
      setSelected(false);
    }
    setSelected(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  return (
    <div className="step step-2 mainContainer flex-center">
      {width < 800 ? <h1>Moving Cost Enquiry</h1> : <div></div>}
      <p>When do you want to move?</p>
      {/* <DateRangePicker
        className="custom-date-picker"
        open={open}
        toggle={toggle}
        onChange={(range) => {
          checkDate();
          setDateRange(Moment(range.startDate).format('YYYY-MM-DD'), Moment(range.endDate).format('YYYY-MM-DD'));
        }}
      /> */}
      <DateRange
        className={classes.picker}
        onChange={(item) => {
          setState([item.selection]);
          checkDate();
          setDateRange(Moment(item.selection.startDate).format('YYYY-MM-DD'), Moment(item.selection.endDate).format('YYYY-MM-DD'));
        }}
        moveRangeOnFirstSelection={false}
        ranges={state}
      />
      <StyledButton
        type="submit"
        aria-label="search"
        onClick={(e) => {
          e.preventDefault();
          if (selected) {
            increaseStepper();
            history.push('/step4');
          }
          setOpenAlert(true);
        }}
      >
        Next
      </StyledButton>
      <Snackbar className={classes.alert} open={openAlert} autoHideDuration={1500} onClose={handleClose}>
        <Alert severity="error">Please select start and end date!</Alert>
      </Snackbar>
    </div>
  );
};
