import '../index.css';
import '../steps.css';
import { Step0 } from '../steps/Step0';
import { Step1 } from '../steps/Step1';
import { Step2 } from '../steps/Step2';
import { Step3 } from '../steps/Step3';
import { Step4 } from '../steps/Step4';
import { Step5 } from '../steps/Step5';
import { Step6 } from '../steps/Step6';
import { StepFinish } from '../steps/StepFinish';
import { useState, useEffect } from 'react';

export const Main = ({ increaseStepper, step, data, setData, sendHook }) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  const stepRender = () => {
    switch (step) {
      case 0:
        return (
          <Step0
            increaseStepper={increaseStepper}
            city={data.city_from}
            setCity={(value) => {
              setData((prevData) => {
                return { ...prevData, city_from: value };
              });
            }}
          ></Step0>
        );
      case 1:
        return (
          <Step1
            width={width}
            increaseStepper={increaseStepper}
            setSize={(value) => {
              setData((prevData) => {
                return { ...prevData, size: value };
              });
            }}
          ></Step1>
        );
      case 2:
        return (
          <Step2
            width={width}
            increaseStepper={increaseStepper}
            date_from={data.date_from}
            date_to={data.date_to}
            setDateRange={(value1, value2) => {
              setData((prevData) => {
                return { ...prevData, date_from: value1, date_to: value2 };
              });
            }}
          ></Step2>
        );
      case 3:
        return (
          <Step3
            width={width}
            increaseStepper={increaseStepper}
            city={data.city_to}
            setTo={(value) => {
              setData((prevData) => {
                return { ...prevData, city_to: value };
              });
            }}
          ></Step3>
        );
      case 4:
        return (
          <Step4
            width={width}
            increaseStepper={increaseStepper}
            setName={(value) => {
              setData((prevData) => {
                return { ...prevData, name: value };
              });
            }}
          ></Step4>
        );
      case 5:
        return (
          <Step5
            width={width}
            increaseStepper={increaseStepper}
            setEmail={(value) => {
              setData((prevData) => {
                return { ...prevData, email: value };
              });
            }}
          ></Step5>
        );
      case 6:
        return (
          <Step6
            width={width}
            increaseStepper={increaseStepper}
            setPhone={(value) => {
              setData((prevData) => {
                return { ...prevData, phone: value };
              });
            }}
          ></Step6>
        );
      case 7:
        return <StepFinish width={width} sendFinish={sendHook} data={data}></StepFinish>;
      default:
        return <Step0 increaseStepper={increaseStepper} sendHook={sendHook}></Step0>;
    }
  };
  return <div className="main">{stepRender()}</div>;
};
