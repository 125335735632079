import '../index.css';
import React from 'react';
import { Link } from 'react-router-dom';

export const Header = (step) => {
  return (
    <div className="header">
      <div className="item">
        <Link to="/">
          <img alt="dollar" src="../images/logo.png" />
        </Link>
      </div>
      {/* <span>{step.step === 0 ? '' : 'Moving Cost Enquiry'}</span> */}
      {/* <div className="item"><p>+357 999 27411</p></div> */}
    </div>
  );
};
