import '../index.css';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <div>
      <div className="flex-center">
        <div className="footer d-flex-sb">
          <div className="item">
            <img alt="dollar" src="../images/dollar.png" />
            <div>
              <p className="large">Save $525</p>
              <p>on average</p>
            </div>
          </div>
          <div className="item">
            <img alt="list" src="../images/list.png" />
            <div>
              <p>See quotes in</p>
              <p className="large">60 seconds</p>
            </div>
          </div>
          <div className="item">
            <img alt="coins" src="../images/coins.png" />
            <div>
              <p className="large">No fees</p>
              <p>Free forever</p>
            </div>
          </div>
          <div className="item">
            <img alt="truck" src="../images/truck.png" />
            <div>
              <p>Compare rates from</p>
              <p className="large">40 moves</p>
            </div>
          </div>
        </div>
      </div>
      <div className="link-pages">
        <Link to="/">
          <span>Home</span>
        </Link>
        <span>|</span>
        <Link to="/contact">
          <span>Contact</span>
        </Link>
        <span>|</span>
        <Link to="/privacy-policy">
          <span>Privacy Policy</span>
        </Link>
        <span>|</span>
        <Link to="/terms-of-use">
          <span>Terms Of Use</span>
        </Link>
      </div>
    </div>
  );
};
