import '../steps.css';
import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 8,
    marginBottom: 22,
    '& button': {
      fontSize: '24px',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'normal',
      borderRadius: '0',
      border: '0.5px solid #D1D1D1',
      color: '#898989',
      width: '270px',
      height: '90px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#F5BC23',
        color: '#fff'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '50px',
        borderRadius: '45px',
        background: '#fff',
        marginBottom: '8px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      background: 'transparent',
      boxShadow: 'none'
    },
    boxShadow: '0px 5px 10px #00000029',
    borderRadius: '25px',
    width: '810px',
    backgroundColor: '#fff',
    '& div.MuiGrid-item': {
      '&:nth-child(1) button': {
        '&:first-child': {
          borderRadius: '25px 0 0 0',
          [theme.breakpoints.down('sm')]: {
            borderRadius: '45px'
          }
        },
        '&:last-child': {
          borderRadius: '0 25px 0 0',
          [theme.breakpoints.down('sm')]: {
            borderRadius: '45px'
          }
        }
      },
      '&:nth-child(2) button': {
        '&:first-child': {
          borderRadius: '0 0 0 25px',
          [theme.breakpoints.down('sm')]: {
            borderRadius: '45px'
          }
        },
        '&:last-child': {
          borderRadius: '0 0 25px 0',
          [theme.breakpoints.down('sm')]: {
            borderRadius: '45px'
          }
        }
      }
    }
  }
}));

export const Step1 = ({ increaseStepper, setSize, width }) => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <div className="step step-1 mainContainer flex-center">
      {width < 800 ? <h1>Moving Cost Enquiry</h1> : <div></div>}
      <p>What is your moving size?</p>
      <Grid
        container
        className={classes.root}
        onClick={(e) => {
          setSize(e.target.innerText);
          increaseStepper();
          history.push('/step3');
        }}
      >
        <Grid item md={12} xs={12}>
          <Button variant="outlined">Studio</Button>
          <Button variant="outlined">1 bedroom</Button>
          <Button variant="outlined">2 bedrooms</Button>
        </Grid>
        <Grid item md={12} xs={12}>
          <Button variant="outlined">3 bedrooms</Button>
          <Button variant="outlined">4+ bedrooms</Button>
          <Button variant="outlined">Office/commercial</Button>
        </Grid>
      </Grid>
    </div>
  );
};
