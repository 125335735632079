import '../steps.css';
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import states from '../utils/states_new.json';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useState } from 'react';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  container: {
    '& div.MuiInputBase-root makeStyles-input-64': {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    background: '#fff',
    padding: '2px 8px',
    borderRadius: '38px',
    display: 'flex',
    alignItems: 'center',
    width: 750,
    height: 66,
    boxShadow: '0px 5px 10px #00000029',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 60,
      flexDirection: 'column',
      borderRadius: '45px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '& button': {
      [theme.breakpoints.down('xs')]: {
        marginTop: '40px',
        width: '104%',
        borderRadius: '45px'
      }
    }
  },
  input: {
    marginLeft: '16px',
    padding: '0',
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0
    },
    '& input': {
      padding: '0',
      fontSize: '24px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
        marginTop: '16px',
        height: '28px',
        textAlign: 'center'
      }
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
      fontSize: '20px'
    },
    flex: 1
  }
}));

const StyledButton = withStyles((theme) => ({
  root: {
    background: 'transparent',
    backgroundColor: '#F5BC23',
    border: 0,
    height: 56,
    width: 240,
    boxShadow: '0px 2px 6px #00000029',
    borderRadius: '38px',
    color: '#fff',
    '& span': {
      fontWeight: 'bold',
      fontSize: '28px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px'
      }
    },
    '&:hover': {
      backgroundColor: '#F5BC23'
    }
  },
  label: {
    textTransform: 'none'
  }
}))(Button);

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  limit: 7,
  stringify: (option) => option.replace(',', '')
});

export const Step0 = ({ increaseStepper, city, setCity }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  let history = useHistory();

  const checkInput = () => {
    console.log(city);
    let options = states.map((item) => `${item.state}, ${item.city}, ${item.zip_code}`);
    return options.includes(city);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="step step-0 mainContainer flex-center">
      <h1>Moving Cost Enquiry</h1>
      <p>Where are you moving from?</p>
      <form
        className={classes.container}
        onSubmit={(e) => {
          e.preventDefault();
          if (checkInput()) {
            increaseStepper();
            history.push('/step2');
          }
          setOpen(true);
        }}
      >
        <Autocomplete
          freeSolo
          disableClearable
          className={classes.input}
          options={states.map((item) => `${item.state}, ${item.city}, ${item.zip_code}`)}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps, type: 'text' }}
              placeholder="Enter city name or ZIP code"
              onSelect={(e) => {
                setCity(e.target.value);
              }}
              required
            />
          )}
        />
        <StyledButton type="submit" aria-label="search">
          Get a quote
        </StyledButton>
        <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
          <Alert severity="error">Please select a valid option and try again!</Alert>
        </Snackbar>
      </form>
    </div>
  );
};
