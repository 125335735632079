import '../steps.css';
import React, { useEffect } from 'react';

export const StepFinish = ({ sendFinish, data, width }) => {
  useEffect(() => {
    sendFinish();
  }, [sendFinish]);
  return (
    <div className="step step-finish mainContainer flex-center">
      {width < 800 ? <h1>Moving Cost Enquiry</h1> : <div></div>}
      <p>Thank you for your time & for choosing FromAZ</p>
      <img alt="finish" src="../images/finish.png" />
      <div className="finish-data">
        <p>
          <span>Name: </span>
          {data.name}
        </p>
        <p>
          <span>Email: </span>
          {data.email}
        </p>
        <p>
          <span>Phone: </span>
          {data.phone}
        </p>
        <p>
          <span>From city: </span>
          {data.city_from}
        </p>
        <p>
          <span>To city: </span>
          {data.city_to}
        </p>
        <p>
          <span>Start date: </span>
          {data.date_from}
        </p>
        <p>
          <span>End date: </span>
          {data.date_to}
        </p>
        <p>
          <span>Size: </span>
          {data.size}
        </p>
      </div>
      <p>Your quote is being calculated.</p>
      <span>One of our agents will call you soon with best available offer on the market.</span>
    </div>
  );
};
