import '../index.css';
import { Header } from '../components/Header';
import Container from '@material-ui/core/Container';
// import Grid from '@material-ui/core/Grid';
import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
// import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      borderRadius: '33px',
      marginBottom: 16,
      width: '100%',
      background: '#fff',
      boxShadow: '0px 5px 10px #00000029',
      '& .MuiInputBase-input': {
        fontSize: 22,
        padding: '16px 32px',
        [theme.breakpoints.down('sm')]: {
          fontSize: 18
        }
      },
      '& .MuiInput-underline:before': {
        border: 'none'
      },
      '& .MuiInput-underline:after': {
        border: 'none'
      }
    }
  },
  formp: {
    fontSize: 24,
    marginTop: 0,
    marginBottom: 16,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: 500,
      padding: '32px 0',
      [theme.breakpoints.down('sm')]: {
        width: 250
      }
    }
  },
  footer: {
    padding: '32px 0',
    borderTop: '1px solid #fff',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      margin: '0 32px',
      fontSize: 20
    },
    '&> span': {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  },
  info: {
    textAlign: 'center',
    '&>p': {
      fontSize: 20,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 18
      }
    },
    '& div': {
      fontSize: 18,
      marginBottom: 32,
      lineHeight: 0.7,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16
      }
    }
  }
}));

// const StyledButton = withStyles((theme) => ({
//   root: {
//     background: 'transparent',
//     backgroundColor: '#F5BC23',
//     border: 0,
//     height: 56,
//     width: '100%',
//     boxShadow: '0px 2px 6px #00000029',
//     borderRadius: '38px',
//     color: '#fff',
//     '& span': {
//       fontWeight: 'bold',
//       fontSize: '28px',
//       [theme.breakpoints.down('xs')]: {
//         fontSize: '24px'
//       }
//     },
//     '&:hover': {
//       backgroundColor: '#F5BC23'
//     }
//   },
//   label: {
//     textTransform: 'none'
//   }
// }))(Button);

export const Contact = () => {
  const classes = useStyles();
  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('');
  // const [message, setMessage] = useState('');

  return (
    <div className="container info">
      <Container>
        <Header step={0}></Header>
        <div className={classes.content}>
          <h1>Contact Us</h1>
          <div className={classes.info}>
            <p>Our Address</p>
            <div>
              <p>417 Bedford Avenue</p>
              <p>New York, NY 10040</p>
            </div>

            <div>{/* <p>info@azmoving.com</p> */}</div>
            <img alt="" src="../images/contact.png" />
          </div>
          {/* <h1>Contact Us</h1>
          <Grid container spacing={6}>
            <Grid item md={12} sm={12} xs={12} lg={7}>
              <form className={classes.root} noValidate autoComplete="off">
                <p className={classes.formp}>First and Last Name *</p>
                <TextField placeholder="Name and surname" onChange={(e) => setName(e.target.value)} />
                <p className={classes.formp}> E-mail Address *</p>
                <TextField placeholder="example@email.com" onChange={(e) => setEmail(e.target.value)} />
                <p className={classes.formp}>Your Message *</p>
                <TextField placeholder="Your message goes here" multiline rows={8} onChange={(e) => setMessage(e.target.value)} />
                <StyledButton>Contact us</StyledButton>
              </form>
            </Grid>
            <Grid item lg={3} className={classes.info}>
              <p>Our Address</p>
              <div>
                <p>The Elbow Rooms, </p>
                <p> 64 Call Lane Leeds, </p>
                <p> LS1, 6DT </p>
                <p> +12 (0) 345 678 9999</p>
              </div>
              <p>Our E-mail</p>
              <div>
                <p>info@azmoving.com</p>
              </div>
            </Grid>
          </Grid> */}
        </div>
        <div className={classes.footer}>
          <Link to="/">
            <span>Home</span>
          </Link>
          <span>|</span>
          <Link to="/contact">
            <span>Contact</span>
          </Link>
          <span>|</span>
          <Link to="/privacy-policy">
            <span>Privacy Policy</span>
          </Link>
          <span>|</span>
          <Link to="/terms-of-use">
            <span>Terms Of Use</span>
          </Link>
        </div>
      </Container>
    </div>
  );
};
