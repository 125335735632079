import '../index.css';
import { Header } from '../components/Header';
import { Main } from '../components/Main';
import { Footer } from '../components/Footer';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import $ from 'jquery';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    background: 'white',
    padding: '3px',
    borderRadius: '22px',
    width: '100%',
    '& .MuiMobileStepper-progress': {
      height: '12px',
      borderRadius: '22px',
      backgroundColor: 'white',
      width: '100%'
    },
    '& .MuiLinearProgress-bar': {
      borderRadius: '22px'
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#F5BC23'
    }
  }
});

export const Landing = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({
    city_from: '',
    city_to: '',
    date_from: '',
    date_to: '',
    name: '',
    email: '',
    phone: '',
    size: '',
    lp_name: 'v1'
  });
  // console.log(data);
  const classes = useStyles();

  const increaseStepper = (event) => {
    return setActiveStep((prevActiveStep) => (prevActiveStep === 7 ? 8 : prevActiveStep + 1));
  };

  const sendHook = () => {
    const url = 'https://from-az.com/api/web_lead/';
    $.ajax({
      data: JSON.stringify(data),
      dataType: 'json',
      processData: false,
      type: 'POST',
      url: url,
      contentType: 'application/json',
      error: function (response) {
        console.log(response);
      }
    });
  };

  return (
    <div className="container">
      <Container>
        <Header step={activeStep}></Header>
        <Main increaseStepper={increaseStepper} step={activeStep} data={data} setData={setData} sendHook={sendHook}></Main>
        <MobileStepper
          variant="progress"
          steps={8}
          position="static"
          activeStep={activeStep === 7 ? 0 : activeStep}
          className={classes.root}
        />
        <Footer></Footer>
      </Container>
    </div>
  );
};
