import '../index.css';
import { Header } from '../components/Header';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    '& p': {
      textAlign: 'justify'
    },
    '& h2': {
      width: '100%',
      textAlign: 'left',
      marginTop: 32,
      marginBottom: 8
    }
  },
  formp: {
    fontSize: 24,
    marginTop: 0,
    marginBottom: 16,
    textAlign: 'left'
  },
  contactinfo: {
    listStyle: 'none',
    display: 'block',
    '& li': {
      marginBottom: 8
    }
  },
  footer: {
    padding: '32px 0',
    marginTop: 32,
    borderTop: '1px solid #fff',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      margin: '0 32px',
      fontSize: 20
    },
    '&> span': {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  },
  info: {
    textAlign: 'left',
    '&>p': {
      fontSize: 22,
      fontWeight: 'bold',
      textAlign: 'left'
    },
    '& div': {
      fontSize: 20,
      marginBottom: 64,
      lineHeight: 0.7
    }
  }
}));

export const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <div className="container info">
      <Container>
        <Header step={0}></Header>
        <div>
          <h1>Privacy Policy</h1>
          <Grid container className={classes.root}>
            <h2>About the Policy</h2>
            <p>
              Protecting your private information is our priority. This Statement of Privacy applies to movers-az.com and FromAZ Network and
              governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to FromAZ
              Network include movers-az.com. The FromAZ Network website is a Moving Company Network site. By using the FromAZ Network
              website, you consent to the data practices described in this statement.
            </p>
            <h2>Collection of your Personal Information</h2>
            <p>
              FromAZ Network may collect personally identifiable information, such as your:
              <ul>
                <li>Name</li>
                <li>E-mail Address</li>
                <li>Phone Number FromAZ</li>
              </ul>
              Network encourages you to review the privacy statements of websites you choose to link to from FromAZ Network so that you can
              understand how those websites collect, use and share your information. FromAZ Network is not responsible for the privacy
              statements or other content on websites outside of the FromAZ Network website.
            </p>
            <h2>Use of your Personal Information</h2>
            <p>
              FromAZ Network collects and uses your personal information to operate its website(s) and deliv-er the services you have
              requested.
            </p>
            <p>
              FromAZ Network may also use your personally identifiable information to inform you of other products or services available
              from FromAZ Network and its affiliates. FromAZ Network may also contact you via surveys to conduct research about your opinion
              of current services or of po-tential new services that may be offered.
            </p>
            <p className="underline">
              FromAZ Network may sell, rent, or lease customer information to third parties for the following reasons:
            </p>
            <h2>Provide Related Home Services Quotes</h2>
            <p>
              FromAZ Network may, from time to time, contact you on behalf of external business partners about a particular offering that
              may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone
              number) is transferred to the third par-ty. FromAZ Network may share data with trusted partners to help perform statistical
              analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are
              prohibited from using your personal information except to provide these services to FromAZ Network, and they are required to
              maintain the confidentiality of your information.
            </p>
            <p>
              FromAZ Network may keep track of the websites and pages our users visit within FromAZ Net-work, in order to determine what
              FromAZ Network services are the most popular. This data is used to deliver customized content and advertising within FromAZ
              Network to customers whose behavior indicates that they are interested in a particular subject area.
            </p>
            <p>
              FromAZ Network will disclose your personal information, without notice, only if required to do so by law or in the good faith
              belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on FromAZ
              Network or the site; (b) protect and de-fend the rights or property of FromAZ Network; and, (c) act under exigent
              circumstances to pro-tect the personal safety of users of FromAZ Network, or the public.
            </p>
            <h2>Automatically Collected Information</h2>
            <p>
              Information about your computer hardware and software may be automatically collected by FromAZ Network. This information can
              include: your IP address, browser type, domain names, access times and referring website addresses. This information is used
              for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the
              FromAZ Network website.
            </p>
            <h2>Use of Cookies</h2>
            <p>
              The FromAZ Network website may use "cookies" to help you personalize your online experi-ence. A cookie is a text file that is
              placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer.
              Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
            </p>
            <p>
              One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to
              tell the Web server that you have returned to a specific page. For exam-ple, if you personalize FromAZ Network pages, or
              register with FromAZ Network site or ser-vices, a cookie helps FromAZ Network to recall your specific information on
              subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping
              addresses, and so on. When you return to the same FromAZ Network website, the in-formation you previously provided can be
              retrieved, so you can easily use the FromAZ Network features that you customized.
            </p>
            <p>
              You have the ability to accept or decline cookies. Most Web browsers automatically accept cook-ies, but you can usually modify
              your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully
              experience the interactive features of the FromAZ Network services or websites you visit.
            </p>
            <h2>Security of your Personal Information</h2>
            <p>
              FromAZ Network secures your personal information from unauthorized access, use, or disclo-sure. AZ Moving uses the SSL
              Protocol for this purpose.
            </p>
            <p>
              When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of
              encryption, such as the Secure Sockets Layer (SSL) protocol.
            </p>
            <h2>Children Under Thirteen</h2>
            <p>
              FromAZ Network does not knowingly collect personally identifiable information from children under the age of thirteen. If you
              are under the age of thirteen, you must ask your parent or guardi-an for permission to use this website.
            </p>
            <h2>Opt-Out & Unsubscribe</h2>
            <p>
              We respect your privacy and give you an opportunity to opt-out of receiving announcements of certain information. Users may
              opt-out of receiving any or all communications from FromAZ Network by contacting us here:
              <ul>
                <li>Email: info@movers-az.com</li>
              </ul>
            </p>
            <h2>Changes to this Statement</h2>
            <p>
              FromAZ Network will occasionally update this Statement of Privacy to reflect company and cus-tomer feedback. FromAZ Network
              encourages you to periodically review this Statement to be informed of how FromAZ Network is protecting your information.
            </p>
            <h2>Contact Information</h2>
            <p>
              FromAZ Network welcomes your questions or comments regarding this Statement of Privacy. If you believe that FromAZ Network has
              not adhered to this Statement, please contact FromAZ Network at:
              <ul>
                <li>Email: info@movers-az.com</li>
              </ul>
            </p>
            <h2>FromAZ Network welcomes your questions or comments regarding the Terms:</h2>
            <p class="max-width">
              <ul className={classes.contactinfo}>
                <li>FromAZ Network</li>
                <li>412 N. Main St Ste 100</li>
                <li>Buffalo, Wyoming 82834</li>
                <li>Email Address: info@movers-az.com</li>
                <li>Telephone number: 111-111-1111</li>
                <li>Effective as of March 01, 2021</li>
              </ul>
            </p>
            <h2 className="underline">For California Consumers The Following Applies</h2>
            <h2>Your Rights and Choices</h2>
            <p>
              The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section
              describes your CCPA rights and explains how to exercise those rights.
            </p>
            <h3>Access to Specific Information and Data Portability Rights</h3>
            <p>
              You have the right to request that we disclose certain information to you about our collection and use of your personal
              information over the past 12 months. Once we receive and confirm your ver-ifiable consumer request, we will disclose to you:
              <ul>
                <li>The categories of personal information we collected about you.</li>
                <li>The categories of sources for the personal information we collected about you.</li>
                <li>Our business or commercial purpose for collecting or selling that personal information.</li>
                <li>The categories of third parties with whom we share that personal information.</li>
                <li>The specific pieces of personal information we collected about you (also called a data portability request)</li>
                <li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</li>
                <ul>
                  <li>sales, identifying the personal information categories that each category of recipi-ent purchased; and</li>
                  <li>
                    disclosures for a business purpose, identifying the personal information categories that each category of recipient
                    obtained.
                  </li>
                </ul>
              </ul>
            </p>
            <h3>Deletion Request Rights</h3>
            <p>
              You have the right to request that we delete any of your personal information that we collect from you and retained, subject
              to certain exceptions. Once we receive and confirm your verifiable con-sumer request, we will delete (and direct our service
              providers to delete) your personal infor-mation from our records, unless an exception applies.
            </p>
            <p>
              We may deny your deletion request if retaining the information is necessary for us or our service providers to:
              <ul>
                <li>
                  Complete the transaction for which we collected the personal information, provide a good or service that you requested,
                  take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform
                  our contract with you.
                </li>
                <li>
                  Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activi-ty, or prosecute those
                  responsible for such activities.
                </li>
                <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
                <li>Comply with a legal obligation.</li>
              </ul>
            </p>
            <h3>Exercising Access, Data Portability, and Deletion Rights</h3>
            <p>
              To exercise the access, data portability, and deletion rights described above, please submit a veri-fiable consumer request to
              us by email on info@movers-az.com
            </p>
            <p>
              Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a
              verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of
              your minor child.
            </p>
            <p>
              You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable
              consumer request must:
              <ul>
                <li>
                  Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal
                  information or an authorized representative. (At times we may require you to confirm a link sent to your phone via SMS or
                  email)
                </li>
                <li>Describe your request with sufficient detail that allows us to properly understand, evalu-ate, and respond to it.</li>
              </ul>
            </p>
            <p>
              We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to
              make the request and confirm the personal information relates to you. Making a verifiable consumer request does not require
              you to create an account with us. We will only use personal information provided in a verifiable consumer request to verify
              the requestor's identity or authority to make the request.
            </p>
            <h3>Response Timing and Format</h3>
            <p>
              We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we re-quire more time (up to 90
              days), we will inform you of the reason and extension period in writ-ing. We will deliver our written response by email to the
              email that was verified. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer
              request's re-ceipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. We
              do not charge a fee to process or respond to your verifiable consumer request un-less it is excessive, repetitive, or
              manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide
              you with a cost estimate before completing your request.
            </p>
            <h2>Non-Discrimination</h2>
            <p>
              We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:
              <ul>
                <li>Deny you goods or services.</li>
                <li>
                  Charge you different prices or rates for goods or services, including through granting dis-counts or other benefits, or
                  imposing penalties.
                </li>
                <li>Provide you a different level or quality of goods or services.</li>
                <li>
                  Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or
                  services.
                </li>
              </ul>
            </p>
          </Grid>
          <div className={classes.footer}>
            <Link to="/">
              <span>Home</span>
            </Link>
            <span>|</span>
            <Link to="/contact">
              <span>Contact</span>
            </Link>
            <span>|</span>
            <Link to="/privacy-policy">
              <span>Privacy Policy</span>
            </Link>
            <span>|</span>
            <Link to="/terms-of-use">
              <span>Terms Of Use</span>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};
